export const init = () => {
    if (document.getElementById('EnergyLabelCreatePdfButton')) {
        const createPdfButton = document.getElementById('EnergyLabelCreatePdfButton');
        const fuelTypeSelect = document.getElementById('FuelTypeSelect');
        const altFuelTypeDiv = document.getElementById('AltFuelTypeDiv');
        const altFuelApprovalText = document.getElementById('AltFuelApprovalLabel');
        const fuelConsumptionText = document.getElementById('FuelConsumptionLabel');
        const fuelConsumption2Text = document.getElementById('FuelConsumption2Label');
        const fuelConsumption2Div = document.getElementById('FuelConsumption2Div');

        var currentFuelType = allFuelTypes.find(x => x.fuelType === fuelTypeSelect.value);
        var typeExceptions = ["el", "naturgas"];

        //console.log(currentFuelType);
        altFuelApprovalText.innerHTML = altFuelApprovalLabel.replace("{0}", currentFuelType.fuelAltType);
        if (currentFuelType.fuelAltType === "" || typeExceptions.indexOf(currentFuelType.fuelType.toLowerCase()) > -1) {
            altFuelTypeDiv.classList.add("is-hidden");
        } else {
            altFuelTypeDiv.classList.remove("is-hidden");
        }
        fuelConsumptionText.innerHTML = fuelConsumptionLabel.replace("{0}", currentFuelType.fuelTitle + " " + currentFuelType.consumptionLabel);
        if (currentFuelType.isHybridCar) {
            fuelConsumption2Div.classList.remove("is-hidden");
            fuelConsumption2Text.innerHTML = fuelConsumption2Label.replace("{0}", currentFuelType.fuelTitle2 + " " + currentFuelType.consumptionLabel2);
        } else {
            fuelConsumption2Div.classList.add("is-hidden");
        }

        createPdfButton.addEventListener('click',
            () => {
                if (document.getElementById('EnergySuccessMessage')) {
                    document.getElementById('EnergySuccessMessage').innerHTML = "";
                }
                if (document.getElementById('EnergyErrorMessage')) {
                    document.getElementById('EnergyErrorMessage').innerHTML = "";
                }
            });
        fuelTypeSelect.addEventListener('change',
            () => {

                var fuelType = allFuelTypes.find(x => x.fuelType === fuelTypeSelect.value);
                //console.log(fuelType);

                if (fuelType.fuelAltType === "" || typeExceptions.indexOf(fuelType.fuelType.toLowerCase()) > -1) {
                    altFuelTypeDiv.classList.add("is-hidden");
                } else {
                    altFuelTypeDiv.classList.remove("is-hidden");
                }
                altFuelApprovalText.innerHTML = altFuelApprovalLabel.replace("{0}", fuelType.fuelAltType);
                fuelConsumptionText.innerHTML = fuelConsumptionLabel.replace("{0}", fuelType.fuelTitle + " " + fuelType.consumptionLabel);
                if (fuelType.isHybridCar) {
                    fuelConsumption2Div.classList.remove("is-hidden");
                    fuelConsumption2Text.innerHTML = fuelConsumption2Label.replace("{0}", fuelType.fuelTitle2 + " " + fuelType.consumptionLabel2);
                } else {
                    fuelConsumption2Div.classList.add("is-hidden");
                }
            });
    }
};