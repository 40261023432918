import React, { Fragment } from 'react';
import { translate } from '../Services/translation';

const Navigation = ({links=[], contentLink=null }) => {
    let menuRef = React.createRef();
    function toggleMenu() {
        menuRef.current.classList.toggle('navbar__menu--show');
    }
    const additionClass = contentLink && contentLink.attributes ? contentLink.attributes.cssValue : null;
    const hasChildrenClass = links.length > 0 ? 'has-children' : null;
    const hasNameOrChildren = link => link.name || (link.links || []).length > 0;

    const openLabel = translate('header.open');
    const backLabel = translate('header.back');

    return (
    <Fragment>
        {!contentLink ? (
            <a className="navbar__link--block navbar__icon--menu navbar__icon" onClick={toggleMenu}></a>
        ) : (
            <Fragment>
                <a className={`navbar__link ${hasChildrenClass || '' } ${additionClass || ''}`} 
                    href={contentLink.url||'#'} dangerouslySetInnerHTML={{__html: contentLink.name}} >
                </a>
                {links.length > 0 &&
                            <a className="navbar__icon" onClick={toggleMenu}>{openLabel}<i className="navbar__icon--caret-right navbar__icon navbar__icon--open"></i></a>
                }
            </Fragment>
        )}

        { links.length > 0 &&
            <ul className="navbar__menu" ref={menuRef} test={links.length}>
                <div className="navbar__menu-header">
                    {!contentLink ? (
                        <span className="navbar__icon navbar__icon--close" onClick={toggleMenu}></span>
                    ) : (
                        <Fragment>
                                <a className="navbar__icon" onClick={toggleMenu}>
                                    <i className="navbar__icon--caret-left navbar__icon"></i>
                                    {backLabel}
                                    {false && <span className="navbar__title" onClick={toggleMenu} dangerouslySetInnerHTML={{ __html: contentLink.name }}></span>}
                                </a>
                        </Fragment>
                    )}
                </div>
                {links.length > 0 && links.map((link, i) => hasNameOrChildren(link) &&
                    <li className= { `navbar__item ${link.isSelected ? 'active': ''}` } key={!link.name ? `${new Date().getTime()}-${i}` : `${link.name}-${i}`}>
                        <Navigation links={link.links} contentLink={link}/>
                    </li>
                )}
            </ul>
        }
        
    </Fragment>
)}

export default Navigation;