const defaultState = { 
    payload: {
        type: '',
        embedReport: [],
        embedToken: null
    }
}

export const powerBi = (state = defaultState, action) => {
    const { type } = action;
    switch (type) {
        default:
            return state;
    }
}